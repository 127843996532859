<script setup>
import Pagination from '@/components/Pagination.vue';

import keys from 'lodash/keys'
import omit from 'lodash/omit'
import pick from 'lodash/pick'

import { getPagination } from '@/utils.js'

import { computed, reactive, watchEffect, ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'

const props = defineProps({
  header: Object,
  items: Array,
})
const route = useRoute()

// onMounted(() => {
//   console.log('table:route')
//   console.log(route.query)
// })

// console.log('table:route')
// console.log(route.query)

 

const tmp = reactive(ref([]))

watchEffect(() => {
  // console.log('watchEffect')
  // console.log(props.items)
  tmp.value = props.items
})

const header_keys = keys(props.header)

const items2 = computed(() => {
  let items = []

  tmp.value.forEach(o => {
    // console.log(keys(o))
    let tmp = pick(o, header_keys)
    // header_keys.forEach(key => {
    //   tmp[key] = o[key]
    // })
    tmp.item = o
    items.push(tmp)
  })
  return items
})


// import {
//   BriefcaseIcon,
//   CalendarIcon,
//   CheckIcon,
//   ChevronDownIcon,
//   CurrencyDollarIcon,
//   LinkIcon,
//   LocationMarkerIcon,
//   PencilIcon,
// } from '@heroicons/vue/solid/index.js'
// import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'



</script>

<template>
  <div>

    <!-- <pre>{{ props.items }}</pre> -->
    <!-- <pre>{{ items2 }}</pre> -->
    <!-- <pre>{{ pick(items[0], header_keys) }}</pre> -->

    <!-- <pre>{{ props.items2 }}</pre> -->

    <table class="w-full text-sm">
      <thead>
        <tr>
          <th v-for="(item, index) in header" :key="index">{{ item }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="o in items2" :key="o.item.id">
          <td v-for="(value, key) in omit(o, ['item'])" :key="key">
            <div v-if="!$slots[key]">{{ value }}</div>
            <div v-else>
              <slot :name="key" :value="value" :item="o" />
            </div>
          </td>
          <td v-if="$slots.controls">
            <slot name="controls" :item="o" />
          </td>

          <!-- <td>{{ item.date }}</td>
          <td>{{ item.title }}</td>
          <td>{{ item.members }}</td>
          <td class="text-sm">
            <router-link :to="`/mysteries/${item.id}`">Редактировать</router-link>
          </td> -->
        </tr>
      </tbody>
    </table>

    <Pagination :count="items.length" />

  </div>
</template>