<script setup>
import Table from '@/components/Table.vue'
import Window from '@/components/Window.vue'
import Content from '@/views/Layouts/Content.vue'

import {
  // BriefcaseIcon,
  // CalendarIcon,
  PlusIcon,
  CogIcon,
  // ChevronDownIcon,
  // CurrencyDollarIcon,
  // LinkIcon,
  // LocationMarkerIcon,
  // PencilIcon,
} from '@heroicons/vue/solid/index.js'

import {
  Dialog,
  DialogPanel,
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'
import { MenuIcon, SearchIcon, ShoppingBagIcon, XIcon } from '@heroicons/vue/outline/index.js'

import { DateTime } from 'luxon'

import { ref, computed } from 'vue'
import store from '../../store'
const tmp = ref([
  {
    id: 1,
    title: "Кто слышит пролитую кровь",
    date: "22.09.2022",
    members: 50
  },
  {
    id: 2,
    title: "Пастырь",
    date: "24.09.2022",
    members: 51
  },
  {
    id: 3,
    title: "Экзерсисы",
    date: "27.09.2022",
    members: 110
  },
  {
    id: 4,
    title: "Жду любви",
    date: "29.09.2022",
    members: 210
  }
])


const header = ref({
  id: 'ID',
  spectacle: 'Спектакль',
  created_at: 'Дата',
  mystery_member: 'Заполненность'
})

const hallCount = ref(250)

const mysteries = computed(() => {
  return store.getters['mystery/GET_LIST']
})

</script>

<template>


  <Content>

    <template #header-controls>
      <!-- <div class="flex gap-3 items-center"> -->
      <span class="sm:ml-3">
        <router-link to="/mysteries/create" class="btn btn-primary">
          <PlusIcon class="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
          Добавить
        </router-link>
      </span>
      <div class="flex-1">
        <input type="text" placeholder="Фильтр" class="w-full" />
      </div>
      <div>
        <button>
          <CogIcon class="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
      <!-- </div> -->
    </template>
    <template #title>
      <div class="">
        <h3 class="text-2xl leading-7 font-medium text-gray-900">Спектакли</h3>
        <p class="mt-1 max-w-2xl text-sm text-gray-500">Расписание спектаклей</p>
      </div>
    </template>
    <template #content>

      <div>
        <Window>
          <!-- <pre>{{ mysteries }}</pre> -->
          <Table :items="mysteries" :header="header">
            <template #mystery_member="membersProps">
              <div :class="{
                'text-red-500': membersProps.value.length <= 100,
                'text-yellow-500': membersProps.value.length > 100 && membersProps.value <= 150,
                'text-green-500': membersProps.value.length > 150 && membersProps.value <= 250
              }">
                <div class="text-xl font-semibold" :title="`${membersProps.value.length} чел. / ${hallCount} мест`">
                  {{ (membersProps.value.length / hallCount) * 100 }}%
                </div>
              </div>
            </template>
            <template #spectacle="spectacleProps">
              <div class="font-semibold">{{ spectacleProps.value.title }}</div>
            </template>
            <template #created_at="itemProps">
              <div class="font-semibold">
                {{  DateTime.fromISO(itemProps.value).setLocale('ru').toLocaleString(DateTime.DATE_HUGE) }}
              </div>
            </template>
            <template #controls="controlsProps">
              <div class="text-sm">
                <router-link :to="`/mysteries/${controlsProps.item.id}`">Редактировать</router-link>
              </div>
            </template>
          </Table>
        </Window>
      </div>

    </template>
  </Content>
</template>

<!-- <script>
import { useStore } from "vuex";
import { computed } from "vue";

export default {
  setup() {
    const store = useStore();

    const userEmail = computed(() => store.state.user);

     const signOut = () => {
      store.dispatch("signOutAction");
    };
    return {
      signOut,
      userEmail,
    };
  },
};
</script> -->
