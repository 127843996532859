<template>
  <div>
    <header class="bg-white shadow">
      <div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 flex items-center justify-between gap-6">
        <div>
          <!-- <h1 class="text-3xl tracking-tight font-bold text-gray-900 "> -->
            <slot name="title" />
          <!-- </h1> -->
        </div>
        <div class="flex-1 flex items-center justify-end gap-6">
          <slot name="header-controls" />
        </div>
      </div>
    </header>
    <main>
      <div class="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <slot name="content" />
      </div>
    </main>
  </div>
</template>